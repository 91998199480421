import { Column, Table as TanTable, flexRender } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import useResizeObserver from 'use-resize-observer';

import styles from './AppTable.module.scss';

export type AppTableProps<TData> = {
  table: TanTable<TData>;
  bottomRef?: (node?: Element) => void;
  estimateRowSize: (rowIndex: number) => number;
};

export function AppTable<TData>(props: AppTableProps<TData>) {
  const { table, bottomRef, estimateRowSize } = props;
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: estimateRowSize,
    getScrollElement: () => tableContainerRef.current,
    //https://github.com/TanStack/table/blob/2ec0d292583510d912758e4360678fcc03108ed0/examples/react/virtualized-rows/src/main.tsx#L87
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const columns = useMemo(() => table.getAllFlatColumns(), [table]);
  const cumulativeSizeRef = useRef<number>(0);
  useEffect(() => {
    cumulativeSizeRef.current = columns.reduce((size, x) => size + x.getSize(), 0);
  }, [columns]);

  const { width: tableContainerWidth } = useResizeObserver<HTMLDivElement>({ ref: tableContainerRef });

  useEffect(() => {
    columns.forEach((x) => {
      x.columnDef.meta = x.columnDef.meta ?? {};
      x.columnDef.meta._computedSize =
        (x.columnDef.size * (tableContainerWidth ?? cumulativeSizeRef.current)) / cumulativeSizeRef.current;
    });
  }, [tableContainerWidth, cumulativeSizeRef.current]);

  const getColumnWidth = useCallback((column: Column<TData, unknown>) => {
    return column.columnDef.meta?._computedSize ?? column.getSize();
  }, []);
  const getColumnMinWidth = useCallback((column: Column<TData, unknown>) => {
    return column.columnDef.meta?.minSize ?? column.getSize();
  }, []);

  const getGridTemplateColumns = () => {
    return table
      .getAllFlatColumns()
      .map((x) => `minmax(${getColumnMinWidth(x)}px, ${getColumnWidth(x)}fr)`)
      .join(' ');
  };

  return (
    <>
      <div ref={tableContainerRef} className={styles.tableContainer}>
        <table style={{ display: 'grid' }}>
          <thead className={styles.tableHead}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                style={{
                  gridTemplateColumns: getGridTemplateColumns(),
                }}
                key={headerGroup.id}
                className={styles.tableHeadRow}
              >
                {headerGroup.headers.map((header) => {
                  const props = header.column.columnDef.meta?.headCellProps;
                  return (
                    <th key={header.id} {...props} className={clsx(styles.tableHeadCell, props?.className)}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody
            className={styles.tableBody}
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <tr
                  data-index={virtualRow.index}
                  ref={(el) => rowVirtualizer.measureElement(el)}
                  key={row.id}
                  className={styles.tableBodyRow}
                  style={{
                    transform: `translateY(${virtualRow.start}px)`,
                    gridTemplateColumns: getGridTemplateColumns(),
                  }}
                  {...(virtualRow.index + 1 === rows.length &&
                    bottomRef && {
                      ref: (el: Element) => {
                        bottomRef(el);
                        rowVirtualizer.measureElement(el);
                      },
                    })}
                >
                  {row.getVisibleCells().map((cell) => {
                    const props = cell.column.columnDef.meta?.bodyCellProps;
                    return (
                      <td key={cell.id} {...props} className={clsx(styles.tableBodyCell, props?.className)}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {table.getRowModel().rows.length === 0 && (
          <div className={styles.tableMessageContainer}>
            <div className={styles.tableMessage}>Nothing to show</div>
          </div>
        )}
      </div>
    </>
  );
}
