import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { $getRoot } from 'lexical';
import React from 'react';

export type TextContentPluginProps = {
  onChange: (textContent: string) => void;
};

export const TextContentPlugin: React.FC<TextContentPluginProps> = ({ onChange }) => {
  return (
    <OnChangePlugin
      onChange={(editorState) => {
        const textContent = editorState.read(() => $getRoot().getTextContent());
        onChange(textContent);
      }}
    />
  );
};
