import { useReactTable } from '@tanstack/react-table';
import { getCoreRowModel, getSortedRowModel } from '@tanstack/table-core';
import React from 'react';
import { NotificationDto } from '../../../api/notifications-api';
import { AppForm } from '../../../components/AppForm/AppForm';
import { AppTable } from '../../../components/AppTable/AppTable';
import { rolesToView } from '../../../constans/UserRoleTypes/utils';
import { useIsoCodes } from '../../../helpers/useIsoCodes';
import { TenantTargetModel, useTargetTenantColumns } from '../common/TenantColumns/TenantColumns';

import styles from './NotificationTargetDisplay.module.scss';

export type NotificationTargetDisplayProps = {
  notification: NotificationDto;
  onClose: () => void;
};

export const NotificationTargetDisplay: React.FC<NotificationTargetDisplayProps> = (props) => {
  const { notification } = props;
  const { getLanguageName } = useIsoCodes();

  const tenantColumns = useTargetTenantColumns({ isReadOnly: true });
  const table = useReactTable<TenantTargetModel>({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    data: notification.tenants.map((x) => {
      return { ...x, isSelected: true };
    }),
    columns: tenantColumns,
  });
  
  const roles = rolesToView(notification.roles);

  return (
    <AppForm title="Notification target">
      <div className={styles.section}>
        <div className={styles.sectionTitle}>Languages</div>
        <div className={styles.sectionContent}>
          {notification.cultures ? notification.cultures.map(getLanguageName).join(', ') : <span>All</span>}
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionTitle}>Roles</div>
        <div className={styles.sectionContent}>
          {roles ? (
            roles.join(', ')
          ) : (
            <span>All</span>
          )}
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionTitle}>Organizations</div>
        {notification.tenants ? (
          <AppTable estimateRowSize={(_) => 72} table={table} />
        ) : (
          <div className={styles.sectionContent}>
            <span>All</span>
          </div>
        )}
      </div>
    </AppForm>
  );
};
