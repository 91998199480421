import React from 'react';
import LinkIcon from '../../../../assets/icons/link.svg';

import { ToolbarButton } from '../../ToolbarButton/ToolbarButton';
import { LinkNode } from '@lexical/link';

export type ToolbarLinkButtonProps = { toggleLink: (url?: string) => boolean; currentLink: LinkNode | null };

export const ToolbarLinkButton: React.FC<ToolbarLinkButtonProps> = (props) => {
  const { toggleLink, currentLink } = props;

  return (
    <ToolbarButton
      isActive={!!currentLink}
      render={LinkIcon}
      renderProps={{}}
      key={'link'}
      onClick={() => {
        toggleLink();
      }}
      title="Insert link (Ctrl+K)"
    />
  );
};
