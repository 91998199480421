import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React, { createContext, useState } from 'react';
import { NumberParam, StringParam, createEnumArrayParam, useQueryParams, withDefault } from 'react-router-url-params';

import { SortingType } from '../../../api/common';
import { NotificationDto, NotificationStatus, NotificationsQueryParameters } from '../../../api/notifications-api';
import { AppModal } from '../../../components/AppModal/AppModal';
import { AppTable } from '../../../components/AppTable/AppTable';
import { ConfirmationModal } from '../../../components/ConfirmationModal/ConfirmationModal';
import { Loading } from '../../../components/Loading/Loading';
import { useInfiniteScrolling } from '../../../helpers/InfiniteScrolling';
import {
  useInvalidateNotificationsPageQuery,
  useNotificationsDeleteMutation,
  useNotificationsDisableMutation,
} from '../../../helpers/react-query/query-hooks';
import { useInfiniteNotificationsQuery } from '../../../helpers/useInfiniteNotificationsQuery';
import { EditNotificationForm } from '../Forms/EditNotificationForm/EditNotificationForm';
import { NotificationTargetDisplay } from '../NotificationTargetDisplay/NotificationTargetDisplay';
import { TopPanel } from '../TopPanel/TopPanel';
import { useNotificationsPageColumns } from './columns';

const defaultPageParams: NotificationsQueryParameters = {
  pageSize: 16,
  sortingType: SortingType.Descending,
  notificationsStatuses: [],
};

export type SelectedNotificationState = {
  notification: NotificationDto;
  setNotification: React.Dispatch<React.SetStateAction<NotificationDto>>;
  notificationState: SelectedNotificationStateType;
  setNotificationState: React.Dispatch<React.SetStateAction<SelectedNotificationStateType>>;
};

export type SelectedNotificationStateType = 'Delete' | 'Disable' | 'Edit' | 'ViewTarget';

export const SelectedNotificationStateContext = createContext<SelectedNotificationState | null>(null);

const NotificationStatusesArrayEnumParam = createEnumArrayParam(
  Object.keys(NotificationStatus) as NotificationStatus[],
);

export const NotificationsPage: React.FC = () => {
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(null);
  const [{ searchQuery, selectedNotificationStatuses, selectedSortingType }, setQueryParams] = useQueryParams({
    searchQuery: withDefault(StringParam, ''),
    selectedNotificationStatuses: withDefault(NotificationStatusesArrayEnumParam, []),
    selectedSortingType: withDefault(NumberParam, defaultPageParams.sortingType),
  });

  const invalidate = useInvalidateNotificationsPageQuery();
  const notificationDisableMutation = useNotificationsDisableMutation(invalidate);
  const notificationDeleteMutation = useNotificationsDeleteMutation(invalidate);

  const handleSearchChange = (newValue: string) => {
    setQueryParams({ searchQuery: newValue });
  };

  const handleSelectedStatusesChange = (statuses: NotificationStatus[]) => {
    setQueryParams({ selectedNotificationStatuses: statuses });
  };

  const handleSortingTypeChange = (sortingType: SortingType) => {
    setQueryParams({ selectedSortingType: sortingType });
  };

  const query = useInfiniteNotificationsQuery({
    ...defaultPageParams,
    searchQuery: searchQuery,
    notificationsStatuses: selectedNotificationStatuses,
    sortingType: selectedSortingType,
  });
  const { isFetching } = query;

  const { ref, flatData } = useInfiniteScrolling(query, ({ items: notifications }) => notifications);

  const [selectedNotification, setSelectedNotification] = useState<NotificationDto | null>(null);
  const [selectedNotificationState, setSelectedNotificationState] = useState<SelectedNotificationStateType | null>(
    null,
  );

  const columns = useNotificationsPageColumns({
    createdAtSortingControl: {
      sortingType: selectedSortingType,
      onSortingChange: handleSortingTypeChange,
    },
  });
  const table = useReactTable<NotificationDto>({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    data: flatData,
    columns: columns,
  });

  const resetNotificationState = () => {
    setSelectedNotification(null);
    setSelectedNotificationState(null);
  };
  return (
    <SelectedNotificationStateContext.Provider
      value={{
        notification: selectedNotification,
        setNotification: setSelectedNotification,
        notificationState: selectedNotificationState,
        setNotificationState: setSelectedNotificationState,
      }}
    >
      <Loading loading={isFetching} />
      <TopPanel
        searchValue={searchQuery}
        onSearchInputStop={handleSearchChange}
        placeholder="Search by notification text or ID"
        requestDelay={800}
        onSelectedStatusesChange={handleSelectedStatusesChange}
        selectedStatuses={selectedNotificationStatuses}
      />
      <AppModal
        open={selectedNotification !== null && selectedNotificationState !== null}
        onClose={resetNotificationState}
        preventClose={isFormSubmitting}
      >
        {selectedNotificationState === 'Edit' && (
          <EditNotificationForm
            setIsFormSubmitting={setIsFormSubmitting}
            notificationData={selectedNotification}
            onSubmitSuccessful={() => {
              invalidate();
              setIsFormSubmitting(false);
              resetNotificationState();
            }}
            onClose={resetNotificationState}
          />
        )}
        {selectedNotificationState === 'ViewTarget' && (
          <NotificationTargetDisplay notification={selectedNotification} onClose={resetNotificationState} />
        )}
        {selectedNotificationState === 'Disable' && (
          <ConfirmationModal
            title="Disable notification?"
            description="This action cannot be revoked. Notification will no longer be shown to the users. 
            It will still remain in the notification list."
            onCancel={resetNotificationState}
            confirmButtonLabel="Disable notification"
            onConfirm={() => {
              notificationDisableMutation.mutate(selectedNotification.id);
              resetNotificationState();
            }}
          />
        )}
        {selectedNotificationState === 'Delete' && (
          <ConfirmationModal
            title="Delete notification?"
            description="Notification will be permanently removed from the notification list. 
            It will no longer be shown to the users."
            onCancel={resetNotificationState}
            confirmButtonLabel="Delete notification"
            onConfirm={() => {
              notificationDeleteMutation.mutate(selectedNotification.id);
              resetNotificationState();
            }}
          />
        )}
      </AppModal>
      <AppTable table={table} bottomRef={ref} estimateRowSize={() => 72} />
    </SelectedNotificationStateContext.Provider>
  );
};
