import React, { useContext } from 'react';

import { NotificationDto, NotificationStatus } from '../../../api/notifications-api';
import { DotMenu, DotMenuOption } from '../../../components/DotMenu/DotMenu';
import {
  useInvalidateNotificationsPageQuery,
  useNotificationsPublishMutation,
  useNotificationsUnpublishMutation,
} from '../../../helpers/react-query/query-hooks';
import { SelectedNotificationStateContext } from '../NotificationsPage/NotificationsPage';
import { AlertContext } from '../../../components/CustomAlert';

import styles from './NotificationDotMenu.module.scss';

export const NotificationDotMenu: React.FC<{ notification: NotificationDto }> = ({ notification }) => {
  const options = useNotificationMenuOptions(notification);
  return <DotMenu options={options} containerProps={{ className: styles.menu }} buttonClassName={styles.menuButton} />;
};

const useNotificationMenuOptions: (notification: NotificationDto) => DotMenuOption[] = (notification) => {
  const opts: DotMenuOption[] = [];
  const { id, status } = notification;

  const invalidate = useInvalidateNotificationsPageQuery();
  const notificationsPublishMutation = useNotificationsPublishMutation(invalidate);
  const notificationsUnpublishMutation = useNotificationsUnpublishMutation(invalidate);

  const selectedNotification = useContext(SelectedNotificationStateContext);
  const { setAlertMessage, setAlertState } = useContext(AlertContext);

  opts.push({
    text: 'Copy ID',
    key: 'CopyIDMenuItem',
    onClick: () => {
      navigator.clipboard.writeText(notification.id);
      setAlertMessage('Notification ID copied');
      setAlertState(true);
    },
  });

  if (status !== NotificationStatus.Disabled && status !== NotificationStatus.Draft) {
    opts.push({
      text: 'Disable',
      type: 'warning',
      key: 'DisableMenuItem',
      onClick: () => {
        selectedNotification.setNotification(notification);
        selectedNotification.setNotificationState('Disable');
      },
    });
  }

  if (status === NotificationStatus.Planned) {
    opts.push({
      text: 'Unpublish',
      key: 'UnpublishMenuItem',
      onClick: () => notificationsUnpublishMutation.mutate(id),
    });
  }

  if (status === NotificationStatus.Draft) {
    opts.push(
      {
        text: 'Publish',
        type: 'info',
        key: 'PublishMenuItem',
        onClick: () => notificationsPublishMutation.mutate(id),
      },
      {
        text: 'Edit',
        key: 'EditMenuItem',
        onClick: () => {
          selectedNotification.setNotification(notification);
          selectedNotification.setNotificationState('Edit');
        },
      },
      {
        text: 'Delete',
        key: 'DeleteMenuItem',
        type: 'danger',
        onClick: () => {
          selectedNotification.setNotification(notification);
          selectedNotification.setNotificationState('Delete');
        },
      },
    );
  }

  return opts;
};
