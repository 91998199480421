import clsx from 'clsx';
import React from 'react';
import styles from './Loading.module.scss';
import { BusyContainer, BusyIndicator } from '../BusyIndicator/BusyIndicator';

type Props = {
  loading: boolean;
  children?: React.ReactNode;
  className?: string;
};

export const Loading: React.FC<Props> = (props) => {
  return (
    <>
      {props.children}
      {props.loading && (
        <BusyContainer className={styles.container} position="center">
          {<BusyIndicator lockUI={true} />}
        </BusyContainer>
      )}
    </>
  );
};
