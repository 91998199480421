import { AuthData } from '../helpers/auth/auth-data';

const storage = window.localStorage;

export const localStorageKeys = {
  authData: 'authData',
};

export const getAuthDataLocalStorage = (): AuthData | null => JSON.parse(storage.getItem(localStorageKeys.authData));
export const setAuthDataLocalStorage = (token: AuthData) =>
  storage.setItem(localStorageKeys.authData, JSON.stringify(token));
