import React, { useContext } from 'react';
import { NotificationDto } from '../../../../api/notifications-api';
import { SelectedNotificationStateContext } from '../NotificationsPage';
import { rolesToView } from '../../../../constans/UserRoleTypes/utils';
import { useIsoCodes } from '../../../../helpers/useIsoCodes';

import styles from './NotificationTargetCell.module.scss';

export type NotificationTargetCellProps = {
  notification: NotificationDto;
  maxVisibleTargetsCount?: number;
};

export const NotificationTargetCell: React.FC<NotificationTargetCellProps> = (props) => {
  const { notification, maxVisibleTargetsCount = 2 } = props;

  const { getLanguageName } = useIsoCodes();
  const { setNotification, setNotificationState } = useContext(SelectedNotificationStateContext);

  const roles = rolesToView(notification.roles);

  const targets = Object.entries({
    ...(notification.cultures && { Languages: notification.cultures.map(getLanguageName) }),
    ...(notification.tenants && { Organizations: notification.tenants.map((x) => x.name) }),
    ...(roles && { Roles: roles }),
  });

  return (
    <div className={styles.target}>
      <div className={styles.targetLinksContainer}>
        {targets.slice(0, maxVisibleTargetsCount).map(([property, value], index) => (
          <div
            onClick={() => {
              setNotification(notification);
              setNotificationState('ViewTarget');
            }}
            className={styles.targetLinkGroup}
            key={property}
          >
            <span className={styles.targetLinkGroupName}>{property + ': '}</span>
            <span className={styles.targetLinkGroupContent}>
              <span className={styles.targetLinkGroupValue}>{value.join(', ')}</span>
              {!!index && targets.length > maxVisibleTargetsCount && (
                <span className={styles.targetLinkMore}>{' + more'}</span>
              )}
            </span>
          </div>
        ))}
      </div>
      {Object.entries(targets).length === 0 && 'None'}
    </div>
  );
};
