import React from 'react';

import { Toolbar } from '@mui/material';
import styles from './RichTextToolbar.module.scss';

export type RichTextToolbarProps = {};

export const RichTextToolbar = React.forwardRef<HTMLDivElement, RichTextToolbarProps>((props, ref) => {
  return (
    <Toolbar
      variant="dense"
      sx={{
        borderWidth: 1,
        borderBottomWidth: 1,
        borderColor: (theme) => theme.palette.grey[300],
        borderBottomColor: (theme) => theme.palette.grey[200],
        backgroundColor: (theme) => theme.palette.grey[100],
        borderStyle: 'solid',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        height: 32,
        minHeight: 'unset',
        padding: '4px !important',
        width: '100%',
        gap: '6px !important',
      }}
    >
      <div className={styles.toolbarContent} ref={ref}>
        {/* action buttons are injected here by other plugins */}
      </div>
    </Toolbar>
  );
});
