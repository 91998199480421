import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as url from 'url';

import { UserRole } from '../constans/UserRoleTypes/UserRole';
import { PageDto, QueryParameters } from './common';
import { TenantTarget } from './target-api';

export enum NotificationStatus {
  Draft = 'Draft',
  Planned = 'Planned',
  Active = 'Active',
  Expired = 'Expired',
  Disabled = 'Disabled',
}

export type NotificationDto = {
  id: string;
  richText: string;
  plainText: string;
  startDate: string;
  endDate: string | null;
  cultures: string[] | null;
  tenants: TenantTarget[] | null;
  status: NotificationStatus;
  createdAt: Date;
  roles: UserRole | null;
};

export type NotificationsQueryParameters = QueryParameters & {
  searchQuery?: string;
  notificationsStatuses?: NotificationStatus[];
};

export type NotificationBaseDto = {
  richText: string;
  plainText: string;
  cultures: string[] | null;
  tenants: TenantTarget[] | null;
  startDate: Date;
  endDate: Date | null;
  roles: UserRole | null;
};

export type NotificationCreateDto = NotificationBaseDto;

export type NotificationEditDto = NotificationBaseDto & {
  id: string;
};

export const NotificationsApi = {
  async getNotifications(
    requestParams: NotificationsQueryParameters,
    options?: AxiosRequestConfig,
  ): Promise<PageDto<NotificationDto>> {
    const params = url.format({ query: requestParams });
    const result: AxiosResponse<PageDto<NotificationDto>> = await axios.get(
      `api/notifications/manager${params}`,
      options,
    );

    return result.data;
  },

  async createNotification(dto: NotificationCreateDto, options?: AxiosRequestConfig): Promise<number> {
    const result: AxiosResponse<number> = await axios.post('api/notifications/create', dto, options);

    return result.data;
  },

  async editNotification(dto: NotificationEditDto, options?: AxiosRequestConfig) {
    return await axios.put('api/notifications/edit', dto, options);
  },

  async publishNotification(id: string, options?: AxiosRequestConfig) {
    const params = url.format({
      query: {
        id,
      },
    });

    return await axios.post(`api/notifications/publish${params}`, undefined, options);
  },

  async unpublishNotification(id: string, options?: AxiosRequestConfig) {
    const params = url.format({ query: { id } });

    return await axios.post(`api/notifications/unpublish${params}`, undefined, options);
  },

  async disableNotification(id: string, options?: AxiosRequestConfig) {
    const params = url.format({ query: { id } });

    return await axios.post(`api/notifications/disable${params}`, undefined, options);
  },

  async deleteNotification(id: string, options?: AxiosRequestConfig) {
    const params = url.format({ query: { id } });
    return await axios.post(`api/notifications/delete${params}`, undefined, options);
  },
};
