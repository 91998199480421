import { MuiPickersAdapterContext } from '@mui/x-date-pickers-pro';
import clsx from 'clsx';
import React, { useContext } from 'react';

import { ReportDateRange } from '../ReportGenerator/types';

import './CalendarDay.css';

type Props<TDate> = {
  onClick: () => void;
  date: TDate;
  outsideCurrentMonth: boolean;
  trendCurveDays?: ReportDateRange[];
  disabled?: boolean;
  isToday?: boolean;
  isHighlighting?: boolean;
  isStartOfHighlighting?: boolean;
  isEndOfHighlighting?: boolean;
  selected?: boolean;
};

type CalendarDayComponent = <TDate>(props: Props<TDate>) => JSX.Element;

export const CalendarDay: CalendarDayComponent = (props) => {
  const localizationContext = useContext(MuiPickersAdapterContext);
  if (props.outsideCurrentMonth) {
    return <div className='calendar-day' />;
  }

  const className = clsx('calendar-day', {
    'calendar-day__active': !props.disabled,
    'calendar-day__highlighting': props.isHighlighting,
    'calendar-day__start-of-highlighting': props.isStartOfHighlighting,
    'calendar-day__end-of-highlighting': props.isEndOfHighlighting,
    'calendar-day__selected-day': props.selected,
  });

  const hasTrendCurve =
    props.trendCurveDays?.some(
      (x) => x.endDate && x.endDate >= props.date && x.startDate && x.startDate <= props.date,
    ) ?? false;

  function handleOnClick() {
    if (props.disabled) {
      return;
    }

    props.onClick();
  }

  return (
    <div className={className} onClick={handleOnClick}>
      <div className='calendar-day__highlight' />
      <div className='calendar-day__hover' />
      {props.isToday && <div className='calendar-day__today' />}
      <div className='calendar-day__day'>
        <div>{localizationContext?.utils?.getDate(props.date)}</div>
        {hasTrendCurve && <div className='calendar-day__circle' />}
      </div>
    </div>
  );
};
