import { Button } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';

import { NotificationStatus } from '../../../api/notifications-api';
import { AppModal } from '../../../components/AppModal/AppModal';
import { MultiSelect } from '../../../components/MultiSelect/MultiSelect';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { useInvalidateNotificationsPageQuery } from '../../../helpers/react-query/query-hooks';
import { CreateNotificationForm } from '../Forms/CreateNotificationForm/CreateNotificationForm';

import styles from './TopPanel.module.scss';

type Props = {
  searchValue: string;
  onSearchInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSearchInputStop?: (newValue: string) => void;
  requestDelay: number;
  placeholder?: string;
  selectedStatuses?: NotificationStatus[];
  onSelectedStatusesChange: (options: NotificationStatus[]) => void;
};

export const TopPanel: React.FC<Props> = ({
  searchValue,
  onSearchInputChange,
  onSearchInputStop,
  placeholder,
  requestDelay,
  onSelectedStatusesChange,
  selectedStatuses = [],
}) => {
  const [isCreateNotificationFormOpen, setIsCreateNotificationFormOpen] = useState<boolean>(false);
  const invalidate = useInvalidateNotificationsPageQuery();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(null);

  return (
    <div className={styles.panel}>
      <SearchInput
        value={searchValue}
        onChange={onSearchInputChange}
        onStop={onSearchInputStop}
        requestDelay={requestDelay}
        placeholder={placeholder}
      />
      <MultiSelect
        onChange={onSelectedStatusesChange}
        options={Object.values(NotificationStatus)}
        defaultValue={selectedStatuses}
        placeholder={'All statuses'}
      />
      <Button
        disableRipple
        sx={{
          minWidth: 142,
        }}
        onClick={() => setIsCreateNotificationFormOpen(true)}
      >
        {'New notification'}
      </Button>
      <AppModal
        open={isCreateNotificationFormOpen}
        onClose={() => setIsCreateNotificationFormOpen(false)}
        preventClose={isFormSubmitting}
      >
        <CreateNotificationForm
          setIsFormSubmitting={setIsFormSubmitting}
          onSubmitSuccessful={() => {
            invalidate();
            setIsCreateNotificationFormOpen(false);
          }}
          onClose={() => {
            setIsCreateNotificationFormOpen(false);
          }}
        />
      </AppModal>
    </div>
  );
};
