export type PageDto<TItem> = {
  items: TItem[];
  pageInfo: PageInfo;
};

type PageInfo = {
  currentPage: number;
  totalPages: number;
  totalItems: number;
};

export type QueryParameters = {
  pageNumber?: number;
  pageSize: number;
  sortingType: SortingType;
};

export enum SortingType {
  Ascending = 0,
  Descending = 1,
}
