import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import { redirectToLoginPage } from './openid/openid-manager';
import { useIsAuthorized } from './helpers/auth/auth-interceptor';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { NotificationsPage } from './features/NotificationsPage/NotificationsPage/NotificationsPage';
import Logo from './assets/icons/logo.svg';
import { AlertProvider } from './components/CustomAlert';

import styles from './App.module.scss';

export function App() {
  const isAuth = useIsAuthorized();

  useEffect(() => {
    if (!isAuth) {
      redirectToLoginPage();
    }
  }, []);

  return (
    <AlertProvider>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.logoContainer}>
                <Logo />
              </div>
            </div>
            <div className={styles.content}>
              <NotificationsPage />
            </div>
          </div>
        </LocalizationProvider>
      </ThemeProvider>
    </AlertProvider>
  );
}
