import React from 'react';

import { SortingType } from '../../api/common';
import SortingDirection from '../../assets/icons/sorting-direction.svg';

import styles from './SortableHeader.module.scss';

export type SortableHeaderProps = {
  sortingControl: SortingControl;
  content: React.ReactNode;
};

export type SortingControl = {
  onSortingChange: (sorting: SortingType) => void;
  sortingType: SortingType;
};

export const SortableHeader: React.FC<SortableHeaderProps> = ({ content, sortingControl }) => {
  const { onSortingChange, sortingType } = sortingControl;

  return (
    <span onClick={() => onSortingChange(getOppositeSortingType(sortingType))} className={styles.sortableHeader}>
      {content}
      <span className={styles.sortingTypeIcon} data-rotate={sortingType !== SortingType.Descending}>
        {<SortingDirection />}
      </span>
    </span>
  );
};

const getOppositeSortingType = (currentType: SortingType) =>
  currentType === SortingType.Ascending ? SortingType.Descending : SortingType.Ascending;
