import React, { useMemo } from 'react';
import { DefaultOptions, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const ConnectQueryClient: React.FC<{ queryClientOptions?: DefaultOptions; children?: React.ReactNode }> = (
  props,
) => {
  const { queryClientOptions } = props;

  const retryFn = (failureNumber: number, e: unknown) => {
    return failureNumber < 3 && ![404, 400].includes((e as AxiosError)?.response?.status);
  };

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          retry: retryFn,
          ...queryClientOptions?.queries,
        },
        mutations: {
          retry: retryFn,
          ...queryClientOptions?.mutations,
        },
      },
    });
  }, [queryClientOptions]);

  return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
};
