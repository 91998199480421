import { QueryKey } from '@tanstack/react-query';
import { NotificationsQueryParameters } from '../../api/notifications-api';

export const getNotificationsPageQueryKey = (queryParams?: NotificationsQueryParameters): QueryKey => {
  const baseKeys = ['notifications', 'list'];
  return queryParams ? [...baseKeys, JSON.stringify(queryParams)] : baseKeys;
};

export const getAvailableTargetsQueryKey = (): QueryKey => ['targets', 'get'];

export const getAvailableCulturesQueryKey = (): QueryKey => ['cultures', 'get'];

export const getAvailableStagesQueryKey = (): QueryKey => ['stages', 'get'];

export const getAvailableCountriesQueryKey = (): QueryKey => ['countries', 'get'];

export const getAvailableTenantsQueryKey = (): QueryKey => ['tenants', 'get'];
