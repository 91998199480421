import { ContentEditable, Props as ContentEditableProps } from '@lexical/react/LexicalContentEditable';
import clsx from 'clsx';
import React from 'react';

import styles from './ContentEditablePlugin.module.scss';

// Passing a ref is required by MUI: https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
export const ContentEditablePlugin = React.forwardRef<ContentEditableProps, ContentEditableProps>((props, ref) => {
  const { className, ...rest } = props;
  return <ContentEditable {...rest} className={clsx(styles.editable, className)} />;
});
