// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__tdDW{height:216;width:480px}.title_k1xp5{color:#525252;font-size:24px;font-style:normal;font-weight:700;line-height:24px;margin-bottom:30px}.description_ovmHo{margin-bottom:30px}.buttonsArea_D3THw{display:flex;justify-content:flex-end}.buttonsArea_D3THw>*{margin-left:10px}`, "",{"version":3,"sources":["webpack://./src/components/ConfirmationModal/ConfirmationModal.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA,iBACE,UAAA,CACA,WAAA,CAGF,aACE,aCkBW,CDjBX,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CAGF,mBACI,kBAAA,CAGJ,mBACI,YAAA,CACA,wBAAA,CAEA,qBACI,gBAAA","sourcesContent":["@import '/src/styles/variables.scss';\n\n.container {\n  height: 216;\n  width: 480px;\n}\n\n.title {\n  color: $title-dark;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 24px;\n  margin-bottom: 30px;\n}\n\n.description {\n    margin-bottom: 30px;\n}\n\n.buttonsArea {\n    display: flex;\n    justify-content: flex-end;\n\n    & > * {\n        margin-left: 10px;\n    }\n}","$primary: #a9cb00;\n$primary-dark: #80b000;\n$normal: #4f4f4f;\n\n$danger-light: #ffccc9;\n$danger: #e74c3c;\n$info-light: #abd7ff;\n$info: #46a3f9;\n$warning-background: #fee5cd;\n$warning-light: #ffeb8e;\n$warning: #f2994a;\n$background: #ffffff;\n\n$text: #1d1d1d;\n\n$border-input: #d3dcea;\n$border-disabled: #e7e7e7;\n$border-focused: $primary;\n$grey-10: #f3f3f3;\n$grey-20: #dfdfdf;\n$grey-30: #c4c4c4;\n$grey-40: #a6a6a6;\n$grey-60: #6e6e6e;\n$caret-color: #344966;\n\n$title-primary: #6e6e6e;\n$title-dark: #525252;\n$title-font-weight: 600;\n$title-font-size: 14px;\n$title-line-height: 16px;\n\n$normal-input-height: 32px;\n$form-input-height: 32px;\n$input-padding: 12px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__tdDW`,
	"title": `title_k1xp5`,
	"description": `description_ovmHo`,
	"buttonsArea": `buttonsArea_D3THw`
};
export default ___CSS_LOADER_EXPORT___;
