import React from 'react';

import styles from './ConfirmationModal.module.scss';
import { Button } from '@mui/material';

type ConfirmationModalProps = {
  title: string;
  description: string;
  onCancel: () => void;
  confirmButtonLabel: string;
  onConfirm: () => void;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  description,
  onCancel,
  confirmButtonLabel,
  onConfirm,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      <div className={styles.buttonsArea}>
        <Button color="secondary" variant="outlined" key={'cancel'} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          sx={{
            marginLeft: '10px',
            color: 'white',
            backgroundColor: '#FF8887',
            ':hover': {
              backgroundColor: '#FF6666',
            },
          }}
          key={'save'}
          onClick={onConfirm}
        >
          {confirmButtonLabel}
        </Button>
      </div>
    </div>
  );
};
