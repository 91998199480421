import { Dialog, DialogContent } from '@mui/material';
import { clsx } from 'clsx';
import React from 'react';
import CloseIcon from '/src/assets/icons/close-cross.svg';

import styles from './AppModal.module.scss';

export type AppModalProps = {
  onClose: () => void;
  open: boolean;
  children: React.ReactNode;
  dialogPaperClassName?: string;
  contentClassName?: string;
  preventClose?: boolean;
};

export const AppModal: React.FC<AppModalProps> = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        if (!props.preventClose) props.onClose();
      }}
      classes={{
        paper: clsx(styles.dialogPaper, styles.powerDialogPaper, props.dialogPaperClassName),
      }}
    >
      <button onClick={props.onClose} className={styles.closeButton} disabled={props.preventClose}>
        <CloseIcon />
      </button>
      <DialogContent className={clsx(styles.content, props.contentClassName)}>{props.children}</DialogContent>
    </Dialog>
  );
};
