import { Button, FormControlLabel, Radio, RadioGroup, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';

import { NotificationBaseDto } from '../../../../api/notifications-api';
import { AppDateTimePicker } from '../../../../components/AppDateTimePicker/AppDateTimePicker';
import { AppForm } from '../../../../components/AppForm/AppForm';
import { CheckboxGroup } from '../../../../components/CheckBoxGroup/CheckboxGroup';
import { Counter } from '../../../../components/Counter/Counter';
import { Field } from '../../../../components/Field/Field';
import { RichTextEditor } from '../../../../components/Lexical/RichTextEditor/RichTextEditor';
import { MultiSelect } from '../../../../components/MultiSelect/MultiSelect';
import { UserRole } from '../../../../constans/UserRoleTypes/UserRole';
import { UserRoleType, availableRoles, roleDescription } from '../../../../constans/UserRoleTypes/utils';
import { useAvailableTargetsQuery } from '../../../../helpers/react-query/query-hooks';
import { useIsoCodes } from '../../../../helpers/useIsoCodes';
import { MaxNotificationLength, validatePlainText } from '../../common/Constants';
import { OrganizationsHint } from '../../common/OrganizationsHint/OrganizationsHint';
import { TenantTargetModel } from '../../common/TenantColumns/TenantColumns';
import { TenantModal } from '../../common/TenantModal/TenantModal';
import { getTenantsAvailableForSelection, tenantsEqual } from '../../common/TenantModal/useSelectedTenants';
import { TenantsModalLink } from '../../common/TenantModalLink/TenantModalLink';
import { NotificationModel } from '../../common/utils';
import { MsgUnexpectedError, UseApplicationFormReturn } from '../useApplicationForm';
import { StagesUnavailableHint } from './StagesUnavailableHint/StagesUnavailableHint';

import styles from './BaseNotificationForm.module.scss';

export type NotificationBaseModel = NotificationModel<NotificationBaseDto>;

export type BaseNotificationFormProps<TModel extends NotificationBaseModel> = {
  title: string;
  form: UseApplicationFormReturn<TModel, any>;
  onClose: () => void;
  onSubmit: (
    data: TModel,
    tenantsAvailableForSelection: TenantTargetModel[],
    event?: React.BaseSyntheticEvent,
  ) => unknown | Promise<unknown>;
  setIsFormSubmitting?: React.Dispatch<React.SetStateAction<boolean>>;
  richTextEditorNamespace?: string;
};

export const BaseNotificationForm = <TModel extends NotificationBaseModel>(
  props: BaseNotificationFormProps<NotificationBaseModel | TModel>,
) => {
  const { form, onSubmit: submitHandler, onClose, setIsFormSubmitting, title, richTextEditorNamespace = title } = props;
  const availableTargetsQuery = useAvailableTargetsQuery();
  const targets = availableTargetsQuery.data;
  useEffect(() => {
    if (availableTargetsQuery.isError)
      form.setError('root', {
        message: MsgUnexpectedError,
      });
    else form.clearErrors('root');
  }, [availableTargetsQuery.isError]);

  const [isTenantsModalOpen, setIsTenantsModalOpen] = useState<boolean>(false);
  const defaultRichTextRef = useRef(!form.getValues('richText') ? null : JSON.stringify(form.getValues('richText')));

  const onSubmit = (data: TModel) => submitHandler(data, tenantsAvailableForSelection);

  const { register, handleSubmit, formState, control, getValues, setValue } = form;
  const { tenants, plainText, countries, stages, endDate, startDate } = form.watch();

  useEffect(() => {
    if (setIsFormSubmitting) setIsFormSubmitting(formState.isSubmitting);
  }, [formState.isSubmitting]);

  const actions = [
    <Button color="secondary" variant="outlined" key={'cancel'} onClick={onClose}>
      Cancel
    </Button>,
    <Button type="submit" key={'save'} disabled={availableTargetsQuery.isError}>
      Save
    </Button>,
  ];

  register('plainText', {
    required: {
      value: true,
      message: 'Notification text is required',
    },
    maxLength: {
      value: MaxNotificationLength,
      message: 'Notification text length should not exceed 120 characters',
    },
    validate: validatePlainText,
  });
  const cultureField = register('cultures');
  const selectedRolesField = register('selectedRoles');
  const targetTypeField = register('targetType', {
    required: {
      value: true,
      message: 'Cultures target type is required.',
    },
  });
  const stagesField = register('stages', {
    onChange: () => {
      setValue(
        'tenants',
        tenants.filter((x) => stages.length === 0 || stages.includes(x.stage)),
      );
    },
  });
  const countriesField = register('countries', {
    onChange: () => {
      setValue(
        'tenants',
        tenants.filter((x) => countries.length === 0 || countries.includes(x.countryCode)),
      );
    },
  });
  const startDateField = register('startDate', {
    required: {
      value: true,
      message: 'Notification start date is required',
    },
    deps: ['endDate'],
  });
  const endDateField = register('endDate', {
    validate: (val) => {
      return endDate && startDate > val ? 'Start date should precede end date' : true;
    },
  });

  const countriesAvailableForSelection = useMemo(
    () =>
      targets?.countries.filter(
        (x) =>
          targets?.tenants
            .filter((tenant) => stages.length === 0 || stages.includes(tenant.stage))
            .map((tenant) => tenant.countryCode)
            .includes(x),
      ) ?? [],
    [targets?.countries, targets?.tenants, stages],
  );

  useEffect(() => {
    const filteredCountries = countries.filter((x) => countriesAvailableForSelection.includes(x));
    setValue('countries', filteredCountries);
    setValue(
      'tenants',
      tenants.filter((x) => filteredCountries.length === 0 || filteredCountries.includes(x.countryCode)),
    );
  }, [countriesAvailableForSelection]);

  const existingTenants = useMemo(
    () =>
      targets?.tenants.map((x) => {
        return { ...x, isSelected: tenants.length === 0 || tenants.some((y) => tenantsEqual(x, y)) };
      }) ?? [],
    [targets?.tenants, tenants],
  );
  const tenantsAvailableForSelection = getTenantsAvailableForSelection(existingTenants, stages, countries);

  const richTextHasError = () => formState.errors.plainText || formState.errors.richText;
  const richTextHelperText = richTextHasError() ? undefined : (
    <Counter left={plainText?.trim().length === 0 ? 0 : plainText?.length ?? 0} right={MaxNotificationLength} />
  );

  const { getCountryName, getLanguageName } = useIsoCodes();
  const theme = useTheme();

  return (
    <AppForm
      title={title}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.form}
      actions={actions}
      helperText={formState.errors.root?.message}
      error={!!formState.errors.root}
      isLoading={formState.isSubmitting || availableTargetsQuery.isFetching}
    >
      <Field title={'Text'}>
        <RichTextEditor
          form={form as any}
          editorNamespace={richTextEditorNamespace}
          helperText={richTextHelperText}
          content={defaultRichTextRef.current}
        />
      </Field>
      <Field title={'Start date'}>
        <Controller
          name={startDateField.name}
          control={control}
          render={({ field }) => (
            <AppDateTimePicker
              error={!!formState.errors.startDate}
              helperText={formState.errors.startDate?.message as any}
              {...field}
            />
          )}
        />
      </Field>
      <Field title={'End date'}>
        <Controller
          name={endDateField.name}
          control={control}
          render={({ field }) => (
            <AppDateTimePicker
              error={!!formState.errors.endDate}
              helperText={(formState.errors.endDate?.message as any) ?? 'Optional'}
              {...field}
            />
          )}
        />
      </Field>
      <Field title={'Target'} fieldClassName={styles.radioGroup}>
        <Controller
          control={control}
          name={targetTypeField.name}
          render={({ field }) => (
            <RadioGroup row style={{ justifyContent: 'space-between' }} {...field}>
              <FormControlLabel value={'All'} control={<Radio />} label={'All users'} />
              <FormControlLabel value={'Specific'} control={<Radio />} label={'Certain group'} />
            </RadioGroup>
          )}
        />
      </Field>
      {getValues(targetTypeField.name) === 'Specific' && (
        <>
          <Field title={'Languages'}>
            <Controller
              name={cultureField.name}
              control={control}
              render={({ field }) => (
                <MultiSelect
                  onChange={field.onChange}
                  placeholder={'All languages'}
                  options={targets?.cultures ?? []}
                  helperText={formState.errors.cultures?.message as any}
                  error={!!formState.errors.cultures}
                  defaultValue={getValues(cultureField.name)}
                  labelStrategy={getLanguageName}
                />
              )}
            />
          </Field>
          <Field title={'Roles'}>
            <Controller
              control={control}
              name={selectedRolesField.name}
              render={({ field: { onChange, value } }) => (
                <CheckboxGroup
                  options={availableRoles.map((availableRole) => {
                    return {
                      value: UserRole[availableRole],
                      label: roleDescription.get(availableRole),
                      selected: value.includes(availableRole),
                    };
                  })}
                  onChange={(selectedRole, checked) => {
                    const role = UserRole[selectedRole as UserRoleType];
                    if (checked) {
                      onChange([...value, role]);
                    } else {
                      onChange([...value.filter((item) => item !== role)]);
                    }
                  }}
                />
              )}
            />
          </Field>
          <Field title={'Stages'} hint={<OrganizationsHint attribute="stages" />}>
            <Controller
              control={control}
              name={stagesField.name}
              render={({ field }) => (
                <MultiSelect
                  onChange={field.onChange}
                  placeholder={'All stages'}
                  options={targets?.stages.filter((x) => x.isAvailable).map((x) => x.name) ?? []}
                  helperTextColor={targets?.stages.some((x) => !x.isAvailable) ? theme.palette.warning.main : undefined}
                  helperText={
                    formState.errors.stages?.message ?? ((<StagesUnavailableHint stages={targets?.stages} />) as any)
                  }
                  error={!!formState.errors.stages}
                  value={field.value}
                />
              )}
            />
          </Field>
          <Field title={'Countries'} hint={<OrganizationsHint attribute="countries" />}>
            <Controller
              control={control}
              name={countriesField.name}
              render={({ field }) => (
                <MultiSelect
                  onChange={field.onChange}
                  placeholder={'All countries'}
                  options={countriesAvailableForSelection}
                  helperText={(formState.errors.countries?.message as any) ?? 'Filtered by selected stages'}
                  error={!!formState.errors.countries}
                  value={field.value}
                  labelStrategy={getCountryName}
                />
              )}
            />
          </Field>
          <Field title={'Organizations'}>
            <TenantsModalLink selectedTenants={tenants} onEditClick={() => setIsTenantsModalOpen(true)} />
            <TenantModal
              open={isTenantsModalOpen}
              onClose={() => setIsTenantsModalOpen(false)}
              onSave={(selectedTenants) => {
                setValue(
                  'tenants',
                  selectedTenants.filter((x) => x.isSelected).length === tenantsAvailableForSelection.length
                    ? []
                    : selectedTenants.filter((x) => x.isSelected),
                );
                setIsTenantsModalOpen(false);
              }}
              selectedStages={stages}
              selectedCountries={countries}
              existingTenants={existingTenants}
            />
          </Field>
        </>
      )}
    </AppForm>
  );
};
