import clsx from 'clsx';
import React from 'react';
import styles from './AppForm.module.scss';
import { Loading } from '../Loading/Loading';

export type AppFormProps = React.HTMLAttributes<HTMLFormElement> & {
  title: string;
  actions?: React.ReactNode[];
  helperText?: React.ReactNode;
  error?: boolean;
  contentClassName?: string;
  isLoading?: boolean;
  preserveInfoPanelSpace?: boolean;
};

export const AppForm: React.FC<AppFormProps> = (props) => {
  const {
    className,
    children,
    title,
    isLoading,
    actions,
    error,
    helperText,
    contentClassName,
    preserveInfoPanelSpace = true,
    ...rest
  } = props;

  return (
    <form className={clsx(className, styles.form)} {...rest}>
      <div className={styles.title}>{title}</div>
      <div className={clsx(styles.contentPanel, contentClassName)}>{children}</div>
      {(helperText || preserveInfoPanelSpace) && (
        <div className={styles.infoPanel}>
          <div className={styles.infoPanelContent} aria-invalid={error}>
            {helperText}
          </div>
        </div>
      )}
      {actions && <div className={clsx(styles.actionsPanel)}>{actions}</div>}
      {props.isLoading !== undefined && <Loading loading={isLoading} />}
    </form>
  );
};
