import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import React, { useMemo } from 'react';

import { NotificationDto } from '../../../api/notifications-api';
import { RichTextViewer } from '../../../components/Lexical/RichTextEditor/RichTextViewer';
import { SortableHeader, SortingControl } from '../../../components/SortableHeader/SortableHeader';
import { NotificationDotMenu } from '../NotificationDotMenu/NotificationDotMenu';
import { StatusInfo } from '../StatusInfo/StatusInfo';
import { NotificationTargetCell } from './NotificationTargetCell/NotificationTargetCell';

import styles from './NotificationsPage.module.scss';

export type NotificationsPageColumnsAttributes = {
  createdAtSortingControl: SortingControl;
};

const columnHelper = createColumnHelper<NotificationDto>();

export const useNotificationsPageColumns: (
  props: NotificationsPageColumnsAttributes,
) => ColumnDef<NotificationDto>[] = ({ createdAtSortingControl }) =>
  useMemo(
    () => [
      {
        id: 'richText',
        accessorKey: 'richText',
        enableSorting: false,
        cell: ({ row }) => (
          <div className={styles.richText}>
            <RichTextViewer editorNamespace="NotificationsList" content={row.original.richText} />
          </div>
        ),
        header: 'TEXT',
        size: 450,
        meta: {
          minSize: 200,
        },
      },
      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        enableSorting: true,
        cell: ({ row }) => <div className={styles.createdAt}>{format(new Date(row.original.createdAt), 'P')}</div>,
        header: () => <SortableHeader content={<>CREATED&nbsp;AT</>} sortingControl={createdAtSortingControl} />,
        size: 130,
      },
      {
        id: 'status',
        accessorKey: 'status',
        enableSorting: false,
        cell: ({ row }) => (
          <div className={styles.status}>
            <StatusInfo
              status={row.original.status}
              startDate={row.original.startDate}
              endDate={row.original.endDate}
            />
          </div>
        ),
        header: 'STATUS',
        size: 200,
        meta: {
          minSize: 220,
        },
      },
      {
        id: 'target',
        accessorKey: 'target',
        enableSorting: false,
        cell: ({ row }) => <NotificationTargetCell notification={row.original} />,
        header: 'TARGET GROUP CONSTRAINTS',
        size: 250,
      },
      columnHelper.display({
        id: 'menu',
        cell: ({ row }) => <NotificationDotMenu notification={row.original} />,
        header: () => null,
        size: 40,
        meta: {
          bodyCellProps: {
            className: styles.menu,
          },
        },
      }),
    ],
    [createdAtSortingControl.sortingType],
  );
