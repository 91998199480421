import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND, TextFormatType } from 'lexical';
import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import BoldIcon from '../../../assets/icons/bold.svg';
import ItalicIcon from '../../../assets/icons/italic.svg';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton';

export type TextFormatPluginProps = {
  toolbarRef: React.MutableRefObject<HTMLDivElement>;
};

export const TextFormatPlugin: React.FC<TextFormatPluginProps> = (props) => {
  const { toolbarRef } = props;
  const [editor] = useLexicalComposerContext();
  const [formats, setFormats] = useState<TextFormatType[]>([]);
  const tags: TextFormatType[] = [...TextFormatIconMap.keys()];
  const onClick = (format: TextFormatType) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, format);
  };

  const updateFormats = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const currentFormats: TextFormatType[] = [];
      TextFormatTypes.forEach((x) => {
        if (selection.hasFormat(x)) currentFormats.push(x);
      });
      setFormats(currentFormats);
    }
  }, [editor]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateFormats();
          });
        }),
      ),
    [editor, updateFormats],
  );

  const [toolbarElement, setToolbarElement] = useState<HTMLDivElement>(toolbarRef.current);
  useEffect(() => {
    setToolbarElement(toolbarRef.current);
  }, [toolbarRef.current]);

  return (
    <>
      {toolbarElement &&
        createPortal(
          <>
            {tags.map((format) => (
              <ToolbarButton
                render={TextFormatIconMap.get(format).render}
                isActive={formats.find((x) => x === format) !== undefined}
                renderProps={{}}
                title={TextFormatIconMap.get(format).title}
                key={format}
                onClick={() => {
                  onClick(format);
                }}
              />
            ))}
          </>,
          toolbarElement,
        )}
    </>
  );
};

const TextFormatIconMap = new Map<TextFormatType, TextFormatButtonInfo>([
  ['bold', { render: BoldIcon, title: 'Bold (Ctrl+B)' }],
  ['italic', { render: ItalicIcon, title: 'Italic (Ctrl+I)' }],
]);

// TS union types are not present at runtime, so in order to iterate through options we have to define an array literal
const TextFormatTypes: TextFormatType[] = [
  'bold',
  'underline',
  'strikethrough',
  'italic',
  'highlight',
  'code',
  'subscript',
  'superscript',
] as const;

type TextFormatButtonInfo = {
  render: React.FC;
  title: string;
};
