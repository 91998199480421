import { LinkNode } from '@lexical/link';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import React from 'react';

import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { ExternalUpdatePlugin } from '../ExternalUpdatePlugin/ExternalUpdatePlugin';
import { RichTextTheme } from './RichTextTheme';

import './EditorTheme.scss';

export type RichTextViewerProps = {
  editorNamespace: string;
  initialEditorConfigOverride?: Partial<InitialConfigType>;
  content?: string;
};

export const RichTextViewer: (props: RichTextViewerProps) => React.ReactElement = (props) => {
  const { editorNamespace, initialEditorConfigOverride, content } = props;

  return (
    <LexicalComposer
      initialConfig={{
        theme: RichTextTheme,
        namespace: editorNamespace,
        nodes: [LinkNode],
        onError: (e) => {
          throw e;
        },
        editable: false,
        ...initialEditorConfigOverride,
      }}
    >
      <RichTextPlugin contentEditable={<ContentEditable />} ErrorBoundary={LexicalErrorBoundary} placeholder={<></>} />
      <ExternalUpdatePlugin trackedState={content} />
    </LexicalComposer>
  );
};
