import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { App } from '../App';
import { Links } from './routes';
import { ConnectQueryClient } from '../helpers/react-query/ConnectQueryClient';

export const AppRouter = () => {
  return (
    <ConnectQueryClient>
      <BrowserRouter>
        <Routes>
          <Route path={Links.Authorized.Notifications.route} element={<App />} />
          <Route path={Links.Unauthorized.Login.route} element={<></>} />
          <Route path={'/*'} element={<Navigate to={Links.Authorized.Notifications.route} />} />
        </Routes>
      </BrowserRouter>
    </ConnectQueryClient>
  );
};
