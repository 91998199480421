import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import { Autocomplete, TextField } from '@mui/material';

import ChevronIcon from '../../assets/icons/chevron.svg';
import CloseIcon from '/src/assets/icons/close-cross.svg';

import styles from './MultiSelect.module.scss';
import './MultiSelect.scss';

type Props = {
  onChange: (values: string[]) => void;
  options: string[];
  placeholder: string;
  defaultValue?: string[];
  value?: string[];
  helperText?: React.ReactNode;
  helperTextColor?: string;
  error?: boolean;
  labelStrategy?: (value: string) => string;
};

export const MultiSelect: React.FC<Props> = ({
  onChange,
  options,
  placeholder,
  defaultValue,
  value,
  helperText,
  helperTextColor,
  error,
  labelStrategy = (val) => val,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(defaultValue ?? []);
  useEffect(() => {
    if (value) setSelectedOptions(value);
  }, [value]);

  const handleMultiSelectChange = (selectedItems: string[]) => {
    const value = selectedItems;
    if (typeof value === 'string') {
      setSelectedOptions([value]);
      return;
    }
    setSelectedOptions(value);
  };

  const handleDeleteItem = (deletedItem: string) => {
    const filteredItems = selectedOptions.filter((item) => item !== deletedItem);
    setSelectedOptions(filteredItems);
    onChange(filteredItems);
  };

  return (
    <Autocomplete
      multiple
      options={options}
      disableCloseOnSelect
      onClose={() => onChange(selectedOptions)}
      onChange={(e, selected, situation) => {
        handleMultiSelectChange(selected);
        if (situation === 'clear') {
          onChange(selected);
        }
      }}
      componentsProps={{
        popupIndicator: {
          disableRipple: true,
          className: styles.icons,
        },
        clearIndicator: {
          disableRipple: true,
          className: styles.clearIcon,
        },
      }}
      popupIcon={<ChevronIcon />}
      clearIcon={<CloseIcon />}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          FormHelperTextProps={{
            color: helperTextColor,
          }}
          placeholder={selectedOptions.length === 0 ? placeholder : ''}
          inputProps={{
            sx: {
              paddingLeft: '10px !important',
            },
            ...params.inputProps,
          }}
          InputProps={{
            ...params.InputProps,
            sx: {
              paddingLeft: '2px !important',
              paddingTop: '2px !important',
              paddingBottom: '2px !important',
            },
          }}
        />
      )}
      renderTags={() =>
        selectedOptions.map((item, index) => (
          <Chip
            label={labelStrategy(item)}
            key={index}
            deleteIcon={<div>×</div>}
            onDelete={() => handleDeleteItem(item)}
            sx={{
              height: 24,
              cursor: 'default',
            }}
          />
        ))
      }
      renderOption={(props, item) => <li {...props}>{labelStrategy(item)}</li>}
      value={selectedOptions}
    />
  );
};
