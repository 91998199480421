import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

type Option = {
  value: string;
  label: string;
  selected: boolean;
};

type Props = {
  options: Option[];
  onChange: (value: string, checked: boolean) => void;
};

export const CheckboxGroup: React.FC<Props> = ({ options, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value, event.target.checked);
  };

  return (
    <FormGroup>
      {options.map((option, index) => (
        <FormControlLabel
          key={index}
          checked={option.selected}
          onChange={handleChange}
          control={<Checkbox size="small" />}
          label={option.label}
          value={option.value}
        />
      ))}
    </FormGroup>
  );
};
