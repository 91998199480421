import { isAxiosError } from 'axios';
import { FieldValues, UseFormProps, UseFormReturn, useForm } from 'react-hook-form';

export type UseApplicationFormReturn<TFieldValues, TContext> = UseFormReturn<TFieldValues, TContext> & {
  overallError?: string;
};

export function useApplicationForm<TFieldValues extends FieldValues = FieldValues, TContext = any>(
  props?: UseFormProps<TFieldValues, TContext>,
): UseApplicationFormReturn<TFieldValues, TContext> {
  const form = useForm<TFieldValues, TContext>(props);

  return {
    ...form,
    handleSubmit: (onValid, onInvalid) => {
      return form.handleSubmit(async (values) => {
        try {
          await onValid(values);
        } catch (e) {
          if (IsValidationError(e)) {
            const errors = e.response.data.errors;
            Object.keys(errors).forEach((key) => {
              const camelCaseKey = GetCamelCaseKey(key);
              const errorValue = errors[key];
              const errorString = Array.isArray(errorValue) ? errorValue.join('; ') : errorValue;
              form.setError(camelCaseKey as any, { message: errorString, type: 'validate' });
            });
          } else {
            form.setError('root', { message: MsgUnexpectedError });
            console.log(e);
          }
        }
      }, onInvalid);
    },
  };
}

export const MsgValidationError = 'One or more validation errors occurred.';
export const MsgUnexpectedError = 'Something went wrong, please try again later.';

const IsValidationError = (e: any) =>
  isAxiosError(e) &&
  e.response.status === 400 &&
  e.response.data.title === MsgValidationError &&
  e.response.data.errors;

const GetCamelCaseKey = (propertyPath: string) => {
  const propertyName = propertyPath.includes('.')
    ? propertyPath.substring(propertyPath.lastIndexOf('.') + 1)
    : propertyPath;
  return propertyName.charAt(0).toLowerCase() + propertyName.slice(1);
};
