import React from 'react';

import { TenantTarget } from '../../../../api/target-api';
import { useIsoCodes } from '../../../../helpers/useIsoCodes';

import styles from './TenantModalLink.module.scss';

export type TenantsModalLinkProps = {
  selectedTenants: TenantTarget[];
  maxVisibleTenantCount?: number;
  onEditClick?: React.MouseEventHandler<HTMLSpanElement>;
};
export const TenantsModalLink: React.FC<TenantsModalLinkProps> = (props) => {
  const { selectedTenants, onEditClick, maxVisibleTenantCount = 3 } = props;

  const allSelected = selectedTenants.length === 0;

  return (
    <div className={styles.linkContainer}>
      {allSelected ? (
        <div className={styles.allSelected}>
          <span>All organizations filtered by stages and countries.</span>
          <span className={styles.organizationsLink} onClick={onEditClick}>
            Select specific
          </span>
        </div>
      ) : (
        <div className={styles.selectedTenantsContainer}>
          {selectedTenants.slice(0, maxVisibleTenantCount).map((x) => (
            <TargetTenantItem tenant={x} key={`${x.stage}-${x.tenant}`} />
          ))}
          {selectedTenants.length > maxVisibleTenantCount && (
            <span className={styles.andMore}>+{selectedTenants.length - maxVisibleTenantCount} more</span>
          )}
          <span className={styles.organizationsLink} onClick={onEditClick}>
            View and edit selection
          </span>
        </div>
      )}
    </div>
  );
};

export type TargetTenantItemProps = {
  tenant: TenantTarget;
};
export const TargetTenantItem: React.FC<TargetTenantItemProps> = (props) => {
  const { tenant } = props;

  const { getCountryName } = useIsoCodes();
  return (
    <div className={styles.tenantItem}>
      <span className={styles.tenantName} key={'name'}>
        {tenant.name + ' '}
      </span>
      <span className={styles.tenantStageAndCountry} key={'info'}>
        {tenant.stage}, {getCountryName(tenant.countryCode)}
      </span>
    </div>
  );
};
