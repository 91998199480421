import React from 'react';

import { StageTarget } from '../../../../../api/target-api';
import { AppHint } from '../../../../../components/AppHint/AppHint';

import styles from './StagesUnavailableHint.module.scss';

export type StagesUnavailableHintProps = {
  stages: StageTarget[];
};

export const StagesUnavailableHint: React.FC<StagesUnavailableHintProps> = (props) => {
  const { stages } = props;

  return (
    <>
      {!stages || !stages.some((x) => !x.isAvailable) ? null : (
        <AppHint component={<span className={styles.message}>Some stages are unavailable</span>}>
          <div className={styles.container}>
            <span>Failed to fetch tenants from the following stages:</span>
            <div className={styles.listContainer}>
              {stages
                .filter((x) => !x.isAvailable)
                .map((x) => (
                  <span className={styles.stage} key={x.name}>
                    {x.name}
                  </span>
                ))}
            </div>
          </div>
        </AppHint>
      )}
    </>
  );
};
