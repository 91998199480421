import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers-pro';
import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import CalendarIcon from '../../assets/icons/calendar.svg';

export type AppDateTimePickerProps = {
  error: boolean;
  helperText: string;
} & Omit<ControllerRenderProps, 'ref'>;

export const AppDateTimePicker = React.forwardRef<HTMLInputElement, AppDateTimePickerProps>((props, ref) => {
  const { error, helperText, ...rest } = props;

  return (
    <DateTimePicker
      ampm={false}
      slotProps={{
        textField: {
          error: error,
          helperText: helperText,
          fullWidth: true,
        },
        openPickerButton: {
          disableRipple: true,
        },
        actionBar: {
          actions: ['clear', 'accept'],
        },
      }}
      slots={{
        openPickerIcon: CalendarIcon,
      }}
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock,
      }}
      {...rest}
      ref={ref}
    />
  );
});
