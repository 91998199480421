import { Popper } from '@mui/material';
import React, { ReactNode, useRef, useState } from 'react';

import HintIcon from '../../assets/icons/questionmark.svg';

import styles from './AppHint.module.scss';

export type AppHintProps = {
  children: ReactNode;
  component?: ReactNode;
};

export const AppHint: React.FC<AppHintProps> = (props) => {
  const { children, component } = props;

  const [hintOpen, setHintOpen] = useState(false);
  const hintIconRef = useRef<HTMLSpanElement>(null);

  return (
    <span
      ref={hintIconRef}
      className={styles.hintContainer}
      onMouseEnter={() => setHintOpen(true)}
      onMouseLeave={() => setHintOpen(false)}
    >
      {component ?? <HintIcon />}
      <Popper
        open={hintOpen}
        anchorEl={hintIconRef.current}
        placement={'top'}
        modifiers={[{ name: 'offset', enabled: true, options: { offset: [0, 10] } }]}
      >
        <div className={styles.hintContent}>{children}</div>
      </Popper>
    </span>
  );
};
