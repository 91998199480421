import clsx from 'clsx';
import React from 'react';

import styles from './ToolbarButton.module.scss';

export type ToolbarButtonProps<TProps> = {
  render: React.FC<TProps>;
  renderProps: TProps;
  isActive?: boolean;
} & React.HTMLAttributes<HTMLSpanElement>;

export const ToolbarButton: <TProps>(props: ToolbarButtonProps<TProps>) => React.ReactElement = (props) => {
  const { render, renderProps, className, isActive, ...rest } = props;
  return (
    <span className={clsx(styles.toolbarButton, className)} data-active={isActive} {...rest}>
      {render(renderProps)}
    </span>
  );
};
