import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

type MountState = {
  isMounted: boolean;
};

export const useDidUpdateEffect = (func: EffectCallback, deps?: DependencyList) => {
  const didComponentMount = useRef(false);

  useEffect(() => {
    if (!didComponentMount.current) {
      didComponentMount.current = true;
    } else {
      func();
    }
  }, deps);
};

export const useAsyncUpdate = <T, TArgs extends Array<any>>(
  callback: (mountState: MountState, ...args: TArgs) => Promise<T>,
) => {
  const objectMounted = useRef<MountState>({ isMounted: true });

  useEffect(() => {
    // looks like it is bug or whatever in react/jest, in jest test
    // effect is called and objectMounted.current is null (it should not be in all cases).
    if (objectMounted.current) {
      objectMounted.current.isMounted = true;
      return () => {
        objectMounted.current.isMounted = false;
      };
    }
  }, []);

  return (...args: TArgs) => callback(objectMounted.current, ...args);
};
