import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { PageDto } from '../api/common';
import { useFlattenedPageData } from './FlattenPageData';

export function useInfiniteScrolling<TData, TPagination extends PageDto<TData>, TModel>(
  query: UseInfiniteQueryResult<PageDto<TData>, unknown>,
  pageParser: (a: TPagination) => TModel[],
) {
  const { fetchNextPage, isError, data } = query;
  const { ref, inView } = useInView();
  const flatData = useFlattenedPageData(data, pageParser);

  // Virtualized table may not render new last row in the table after loading a new page
  // and for some reason inView is TRUE in this situation, so in order to determine
  // whether last element is actually rendered we introduce our own ref
  const trackingRef = useRef<Element>();

  useEffect(() => {
    if (isError) return;
    if (
      trackingRef.current &&
      inView &&
      flatData &&
      flatData.length < data.pages[0].pageInfo.totalItems &&
      !query.isFetchingNextPage
    ) {
      fetchNextPage({
        pageParam: data?.pages ? data.pages.length + 1 : 1,
      });
    }
  }, [inView, flatData, query.isFetchingNextPage, trackingRef.current]);

  return {
    ref: (el: Element) => {
      trackingRef.current = el;
      ref(el);
    },
    flatData,
  };
}
