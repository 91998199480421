import { Checkbox } from '@mui/material';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import React, { useMemo } from 'react';

import { TenantTarget } from '../../../../api/target-api';
import { useIsoCodes } from '../../../../helpers/useIsoCodes';

import styles from './TenantCoumns.module.scss';

const columnHelper = createColumnHelper<TenantTargetModel>();

export type TenantTargetModel = TenantTarget & {
  isSelected: boolean;
};

export type TargetTenantColumnsProps =
  | {
      isReadOnly: false;
      addTenants: (tenants: TenantTarget[]) => void;
      removeTenants: (tenants: TenantTarget[]) => void;
    }
  | {
      isReadOnly: true;
      addTenants?: (tenants: TenantTarget[]) => void;
      removeTenants?: (tenants: TenantTarget[]) => void;
    };

export const useTargetTenantColumns: (props: TargetTenantColumnsProps) => ColumnDef<TenantTargetModel>[] = ({
  isReadOnly,
  addTenants,
  removeTenants,
}) => {
  const { getCountryName } = useIsoCodes();

  return useMemo(
    () => [
      ...(isReadOnly
        ? []
        : [
            columnHelper.display({
              id: 'menu',
              header: (context) => (
                <Checkbox
                  sx={{
                    padding: 0,
                  }}
                  checked={context.table.getRowModel().flatRows.every((x) => x.original.isSelected)}
                  onChange={(e) => {
                    const tenants = context.table.getRowModel().flatRows.map((x) => x.original);
                    e.target.checked ? addTenants(tenants) : removeTenants(tenants);
                  }}
                  disabled={context.table.getRowModel().flatRows.length === 0}
                />
              ),
              cell: ({ row }) => (
                <Checkbox
                  sx={{
                    padding: 0,
                  }}
                  checked={row.original.isSelected}
                  onChange={(e) => (e.target.checked ? addTenants([row.original]) : removeTenants([row.original]))}
                />
              ),
              size: 50,
              meta: {
                headCellProps: {
                  className: styles.checkboxCell,
                },
                bodyCellProps: {
                  className: styles.checkboxCell,
                },
              },
            }),
          ]),
      {
        id: 'name',
        accessorKey: 'name',
        cell: ({ row }) => <span className={styles.name}>{row.original.name}</span>,
        header: 'NAME',
        size: 220,
        meta: {
          headCellProps: {
            className: styles.nameCell,
          },
          bodyCellProps: {
            className: styles.nameCell,
          },
        },
      },
      {
        id: 'stage',
        accessorKey: 'stage',
        cell: ({ row }) => <span className={styles.stage}>{row.original.stage}</span>,
        header: 'STAGE',
        size: 100,
      },
      {
        id: 'country',
        accessorKey: 'country',
        cell: ({ row }) => <span className={styles.country}>{getCountryName(row.original.countryCode)}</span>,
        header: 'COUNTRY',
        size: 120,
      },
    ],
    [addTenants, removeTenants],
  );
};
