import { LinkNode } from '@lexical/link';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TextField } from '@mui/material';
import { EditorState } from 'lexical';
import React, { useRef } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { ContentEditablePlugin } from '../ContentEditablePlugin/ContentEditablePlugin';
import { DisableMultilinePlugin } from '../DisableMultilinePlugin/DisableMultilinePlugin';
import { ExternalUpdatePlugin } from '../ExternalUpdatePlugin/ExternalUpdatePlugin';
import { LinkPlugin } from '../LinkPlugin/LinkPlugin';
import { RichTextToolbar } from '../RichTextToolbar/RichTextToolbar';
import { TextContentPlugin } from '../TextContentPlugin/TextContentPlugin';
import { TextFormatPlugin } from '../TextFormatPlugin/TextFormatPlugin';
import { RichTextTheme } from './RichTextTheme';

import './EditorTheme.scss';

export type RichTextEditorProps<TModel extends RichTextEditorFormModel> = {
  form: UseFormReturn<TModel, any, undefined>;
  content?: string;
  editorNamespace: string;
  editorConfigOverride?: Partial<InitialConfigType>;
  helperText?: React.ReactNode;
};

export type RichTextEditorFormModel = { richText: EditorState; plainText: string };

export const RichTextEditor: <TModel extends RichTextEditorFormModel>(
  props: RichTextEditorProps<TModel>,
) => React.ReactElement = (props) => {
  const { form, editorNamespace, editorConfigOverride, content, helperText } = props;
  const { formState, control } = form;

  const toolbarRef = useRef<HTMLDivElement>(null);

  const updateText = (text: string) => {
    form.setValue('plainText' as any, text as any, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: text.length > 0,
    });
  };
  const validateText = () => {
    form.trigger('plainText' as any);
  };

  return (
    <Controller
      name={'richText' as any}
      control={control}
      render={({ field }) => (
        <LexicalComposer
          initialConfig={{
            theme: RichTextTheme,
            namespace: editorNamespace,
            nodes: [LinkNode],
            onError: (e) => {
              throw e;
            },
            ...editorConfigOverride,
          }}
        >
          <RichTextToolbar ref={toolbarRef} />
          <RichTextPlugin
            contentEditable={
              <TextField
                InputProps={{
                  inputComponent: ContentEditablePlugin as any,
                  inputProps: {
                    ...field,
                    onBlur: validateText,
                  },
                  sx: { padding: '12px', borderTopLeftRadius: 0, borderTopRightRadius: 0, overflow: 'visible' },
                }}
                multiline={true}
                fullWidth={true}
                error={!!formState.errors.plainText}
                helperText={
                  helperText ??
                  (formState.errors.richText?.message as any) ??
                  (formState.errors.plainText?.message as any)
                }
              />
            }
            ErrorBoundary={LexicalErrorBoundary}
            placeholder={<></>}
          />
          <OnChangePlugin onChange={field.onChange} />
          <HistoryPlugin />
          <ExternalUpdatePlugin trackedState={content} />
          <TextFormatPlugin toolbarRef={toolbarRef} />
          <LinkPlugin toolbarRef={toolbarRef} />
          <DisableMultilinePlugin />
          <TextContentPlugin onChange={updateText} />
        </LexicalComposer>
      )}
    />
  );
};
