import { format } from 'date-fns';
import React from 'react';

import { NotificationStatus } from '../../../api/notifications-api';
import Ellipse from '../../../assets/icons/ellipse.svg';

import styles from './StatusInfo.module.scss';

export const StatusInfo: React.FC<{ status: NotificationStatus; startDate: string; endDate: string | null }> = ({
  status,
  endDate,
  startDate,
}) => {
  const formatAsDate = (date: string) => format(new Date(date), `P\xa0HH:mm`);
  const activePeriod = `${formatAsDate(startDate)}\xa0\u2014\xa0${
    endDate === null ? 'Forever' : formatAsDate(endDate)
  }`;

  return (
    <div className={styles.container}>
      <div className={styles.info} data-notification-status={status}>
        <Ellipse className={styles.ellipse} />
        <div>{status}</div>
      </div>
      <div className={styles.period} title={activePeriod}>
        {activePeriod}
      </div>
    </div>
  );
};
