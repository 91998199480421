import { $isLinkNode, LinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LinkPlugin as LexicalLinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { $isAtNodeEnd } from '@lexical/selection';
import { $findMatchingParent, mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_LOW,
  COMMAND_PRIORITY_NORMAL,
  KEY_MODIFIER_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Updater, useImmer } from 'use-immer';

import { getSelectedNode } from '../utils/getSelectedNode';
import { LinkEditorPlugin } from './LinkEditorPlugin/LinkEditorPlugin';
import { ToolbarLinkButton } from './ToolbarLinkButton/ToolbarLinkButton';
import { CheckUrlValid } from './utils/UrlUtils';

export type LinkInfo = {
  currentLink: LinkNode | null;
  currentLinkUrl?: string;
  isEditMode: boolean;
  isEditorOpen: boolean;
  isDirty: boolean;
};

export const LinkInfo = createContext<[LinkInfo, Updater<LinkInfo>]>(null);

export type LinkPluginProps = {
  toolbarRef: React.MutableRefObject<HTMLDivElement>;
};

export const LinkPlugin: React.FC<LinkPluginProps> = (props) => {
  const { toolbarRef } = props;
  const [editor] = useLexicalComposerContext();
  const [linkInfo, setLinkInfo] = useImmer<LinkInfo>({
    isEditMode: false,
    currentLink: null,
    isEditorOpen: false,
    isDirty: false,
  });

  const [toolbarElement, setToolbarElement] = useState<HTMLDivElement>(toolbarRef.current);
  useEffect(() => {
    setToolbarElement(toolbarRef.current);
  }, [toolbarRef.current]);

  const updateLink = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const selectedNode = getSelectedNode(selection);
      const linkNode = $findMatchingParent(selectedNode, $isLinkNode) as LinkNode;
      if (linkNode) {
        setLinkInfo((x) => {
          x.currentLink = linkNode;
          x.currentLinkUrl = linkNode.__url;
        });
      } else {
        setLinkInfo((x) => {
          x.currentLink = null;
          x.currentLinkUrl = undefined;
        });
      }
    }
  }, [editor, setLinkInfo]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLink();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLink();
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      editor.registerCommand(
        CLICK_COMMAND,
        (event) => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            const linkNode = $findMatchingParent(node, $isLinkNode);
            if (linkNode) {
              if (event.metaKey || event.ctrlKey) {
                try {
                  window.open(linkNode.getURL(), '_blank');
                } catch (e) {
                  if (e instanceof DOMException && !CheckUrlValid(linkNode.getURL()))
                    setLinkInfo((x) => {
                      x.isEditMode = true;
                      x.isEditorOpen = true;
                      x.isDirty = true;
                    });
                  else throw e;
                }
              } else {
                if (
                  !(
                    $isAtNodeEnd(selection.isBackward() ? selection.anchor : selection.focus) && selection.isCollapsed()
                  ) &&
                  selection.focus.getNode() == selection.anchor.getNode()
                )
                  setLinkInfo((x) => {
                    x.isEditorOpen = true;
                  });
              }
              return true;
            }
          }
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [updateLink, editor, setLinkInfo]);

  const linkAttributes = {
    target: '_blank',
    rel: 'noopener noreferrer',
  };

  let toggle = useCallback(
    (url?: string) => {
      if (!linkInfo.currentLink) {
        url = url ?? 'https://';
        setLinkInfo((x) => {
          x.isEditMode = true;
          x.isEditorOpen = true;
        });
      } else {
        url = url ?? null;
        setLinkInfo((x) => {
          x.isEditMode = false;
        });
      }
      return editor.dispatchCommand(TOGGLE_LINK_COMMAND, { url: url, ...linkAttributes });
    },
    [editor, linkInfo, setLinkInfo],
  );

  useEffect(() => {
    return editor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (event) => {
        const { code, ctrlKey, metaKey } = event;

        if (code === 'KeyK' && (ctrlKey || metaKey)) {
          event.preventDefault();
          return toggle();
        }
        return false;
      },
      COMMAND_PRIORITY_NORMAL,
    );
  }, [editor, linkInfo, toggle]);

  return (
    <LinkInfo.Provider value={[linkInfo, setLinkInfo]}>
      <LexicalLinkPlugin />
      <LinkEditorPlugin toggleLink={toggle} />

      {toolbarElement &&
        createPortal(<ToolbarLinkButton currentLink={linkInfo.currentLink} toggleLink={toggle} />, toolbarElement)}
    </LinkInfo.Provider>
  );
};
