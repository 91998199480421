import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React, { useEffect } from 'react';

export type ExternalUpdatePluginProps = {
  trackedState: string;
};

export const ExternalUpdatePlugin: (props: ExternalUpdatePluginProps) => React.ReactElement = (props) => {
  const { trackedState } = props;
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (trackedState) {
      const state = editor.parseEditorState(trackedState);
      editor.setEditorState(state);
    }
  }, [trackedState]);

  return null;
};
