import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { TextField } from '@mui/material';
import React, { ButtonHTMLAttributes, DetailedHTMLProps, useContext, useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import CrossIcon from '../../../../assets/icons/cross.svg';
import EditIcon from '../../../../assets/icons/edit.svg';
import TickIcon from '../../../../assets/icons/tick.svg';
import TrashIcon from '../../../../assets/icons/trashbin.svg';
import { AppModal } from '../../../AppModal/AppModal';
import { LinkInfo } from '../LinkPlugin';
import { MsgInvalidUrl, ValidUrlRegex } from '../utils/UrlUtils';

import styles from './LinkEditorPlugin.module.scss';

export type LinkEditorPluginProps = {
  toggleLink: (url?: string) => boolean;
};

export const LinkEditorPlugin: React.FC<LinkEditorPluginProps> = (props) => {
  const [editor] = useLexicalComposerContext();
  const { toggleLink } = props;
  const [currentLinkUrl, setCurrentLinkUrl] = useState('');
  const [linkInfo, setLinkInfo] = useContext(LinkInfo);
  const inputRef = useRef<HTMLInputElement>(null);
  const editorRef = useRef<HTMLDivElement | null>(null);

  const linkForm = useForm<{ url: string }>({ defaultValues: { url: linkInfo.currentLinkUrl }, mode: 'onBlur' });
  const { formState, control, handleSubmit } = linkForm;
  const url = linkForm.register('url', {
    required: {
      value: true,
      message: 'Link url is required',
    },
    pattern: {
      value: ValidUrlRegex,
      message: MsgInvalidUrl,
    },
  });

  const onSubmit: SubmitHandler<{ url: string }> = ({ url }) => {
    toggleLink(url);
    setLinkInfo((x) => {
      x.isEditMode = false;
    });
  };

  useEffect(() => {
    if (linkInfo.currentLink) {
      setCurrentLinkUrl(linkInfo.currentLinkUrl);
      linkForm.setValue('url', linkInfo.currentLinkUrl, {
        shouldDirty: linkInfo.isDirty,
        shouldTouch: linkInfo.isDirty,
        shouldValidate: linkInfo.isDirty,
      });
    }
  }, [linkInfo]);

  useEffect(() => {
    if (linkInfo && inputRef.current && linkInfo.isEditorOpen) {
      inputRef.current.focus();
    }
  }, [inputRef.current, linkInfo]);

  const handleInputInteraction = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit(onSubmit)();
    } else if (event.key === 'Escape') {
      event.stopPropagation();
      event.preventDefault();
      setLinkInfo((x) => {
        x.isEditMode = false;
      });
    }
  };

  const onClose = () => {
    setLinkInfo((x) => {
      x.isEditMode = false;
      x.isEditorOpen = false;
    });
  };

  const editingActions = (
    <>
      <ActionButton
        tabIndex={0}
        title="Cancel edit"
        onMouseDown={(event) => event.preventDefault()}
        onClick={() => {
          setLinkInfo((x) => {
            x.isEditMode = false;
          });
        }}
        children={<CrossIcon />}
      />
      <ActionButton
        tabIndex={0}
        title="Save edit"
        disabled={!!formState.errors.url}
        onMouseDown={(event) => event.preventDefault()}
        onClick={() => {
          handleSubmit(onSubmit)();
        }}
        children={<TickIcon />}
      />
    </>
  );

  const viewingActions = (
    <>
      <ActionButton
        tabIndex={0}
        title="Edit link"
        onMouseDown={(event) => event.preventDefault()}
        onClick={() => {
          setLinkInfo((x) => {
            x.isEditMode = true;
          });
        }}
        children={<EditIcon />}
      />
      <ActionButton
        title="Delete link"
        tabIndex={0}
        onMouseDown={(event) => event.preventDefault()}
        onClick={() => {
          toggleLink();
        }}
        children={<TrashIcon />}
      />
    </>
  );

  return (
    <AppModal
      dialogPaperClassName={styles.editorModal}
      open={linkInfo.isEditorOpen && !!linkInfo.currentLink}
      onClose={onClose}
    >
      <>
        {linkInfo.currentLink && linkInfo.isEditorOpen && (
          <div ref={editorRef} className={styles.editor}>
            {!linkInfo.currentLink ? null : linkInfo.isEditMode ? (
              <>
                <form className={styles.inputContainer}>
                  <Controller
                    name={url.name}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        error={fieldState.isDirty ? !!fieldState.error : false}
                        helperText={fieldState.isDirty ? fieldState.error?.message : null}
                        autoFocus={true}
                        fullWidth={true}
                        inputRef={inputRef}
                        onKeyDown={(event) => {
                          handleInputInteraction(event);
                        }}
                      />
                    )}
                  />
                </form>
                <div className={styles.actionsContainer}>{editingActions}</div>
              </>
            ) : (
              <>
                <div className={styles.inputContainer}>
                  <TextField
                    fullWidth={true}
                    onKeyDown={(event) => {
                      if (event.key === 'Escape') {
                        event.stopPropagation();
                        event.preventDefault();
                        onClose();
                      }
                    }}
                    disabled={true}
                    value={currentLinkUrl}
                  />
                </div>
                <div className={styles.actionsContainer}>{viewingActions}</div>
              </>
            )}
          </div>
        )}
      </>
    </AppModal>
  );
};

const ActionButton: React.FC<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> = (
  props,
) => <button className={styles.actionButton} type="button" {...props}></button>;
