import React, { ChangeEvent, useState } from 'react';

import { useDidUpdateEffect } from '../../helpers/effects-helpers';
import { Input, Props as InputProps } from '../Input/Input';

type Props = InputProps & {
  requestDelay: number;
  onStop: (newValue: string) => void;
  reserveHelperSpace?: boolean;
};

export const SearchInput = ({
  onChange,
  onStop,
  requestDelay,
  reserveHelperSpace = false,
  value,
  ...inputProps
}: Props) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [search, setSearch] = useState(value);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  useDidUpdateEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => onStop(search as any), requestDelay);

    setTimer(newTimer);
  }, [search]);

  return <Input {...inputProps} onChange={handleChange} reserveHelperSpace={reserveHelperSpace} value={search} />;
};
