import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { AppRouter } from './navigation';
import { OpenIdCallback } from './openid/OpenIdCallback';
import { backendUri } from './openid/openid-settings';
import { postServerLogOut, setAuthDataState, setupAuthInterceptor } from './helpers/auth/auth-interceptor';
import { sendRefreshTokenRequest } from './helpers/auth/auth-client';
import axios from 'axios';

import './index.scss';
import { Loading } from './components/Loading/Loading';

setupAuthInterceptor(axios, async (authData) => await sendRefreshTokenRequest(authData.refresh_token));

const rootElement = document.getElementById('root');

const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <OpenIdCallback
      signInRedirectHandler={(user) => {
        setAuthDataState({
          access_token: user.access_token,
          refresh_token: user.refresh_token!,
        });
        window.history.pushState(null, '', backendUri);
      }}
      signOutRedirectHandler={() => postServerLogOut()}
      loading={<Loading loading={true}/>}
      error={(e) => <div>{`Error: ${e.error}`}</div>}
    >
      <AppRouter />
    </OpenIdCallback>
  </StrictMode>,
);
